import {DiGithubBadge} from 'react-icons/di'
import eco from '../Imagesprojects/ecomerce.PNG'
import pror from '../Imagesprojects/portfolio1.PNG'
import inst1 from '../Imagesprojects/instagram-clonm.PNG'
import inst2 from '../Imagesprojects/inistagram.PNG'
import pers from '../Imagesprojects/personalweb.PNG'
import Eco from '../Imagesprojects/ecommerceres.PNG'
import { motion } from 'framer-motion'
import Haaraay from './Haaraay'
import Sklstable from './Sklstable'

const Projects = () => {
  return (
    <>
 <div className='mt-5  h-36 text-center items-center bg-gradient-to-b from-[#3D00B6] to-red-100 rounded-r-full'>
 <h1 className="text-white text-6xl pt-7 items-center">Some of the things I did...</h1>
  </div>  
  <p className='text-center text-gray-500 font-bold mt-7'>Haaraay is the place where creative people  invent, generate, develop and produce a tons of ideas everyday</p>

  <motion.div className='grid grid-cols-1 md:grid-cols-2 mt-12 gap-10 w-full bg-white pb-4 '
  initial={{y: 100}} animate={{y: 0}}>
    <div className='text-center items-center justify-center'>
      <h2 className='text-2xl leading text-[#8C52FF] text-center mb-4'>Ecommerce website</h2>
        <img src={eco} alt="Ecomerce" />
        <p className='text-gray-400 mt-4 mb-4'>It is a commercial website that I have tested</p>
        <div className='flex space-x-12 text-gray-400 text-center items-center justify-center'>
          <span>I used this</span>
          <p className='p-1 border-b text-gray-300'>Html, React and TaiwindCss</p>
          <a href='https://github.com/abshirafrica/Ecommerce' target="_blank">
            <DiGithubBadge className='text-3xl hover:text-gray-500 hover:scale-105 cursor-pointer duration-300' /></a>
          
        </div>
    </div>
    <div className='text-center items-center justify-center'>
      <h2 className='text-2xl leading text-[#8C52FF] text-center mb-4'>Website portfolio</h2>
        <img src={pror} alt="Ecomerce" />
        <p className='text-gray-400 mt-4 mb-4'>It's a prortifolio website that I've looked at carefully</p>
        <div className='flex space-x-12 text-gray-400 text-center items-center justify-center'>
          <span>I used this</span>
          <p className='p-1 border-b text-gray-300'>Html, Css, JavaScript and Php</p>
          <a href='https://github.com/abshirafrica/Website-Portfolio-' target="_blank">
            <DiGithubBadge className='text-3xl hover:text-gray-500 hover:scale-105 cursor-pointer duration-300' /></a>
          
        </div>
    </div>
    <div className='text-center items-center justify-center'>
      <h2 className='text-2xl leading text-[#8C52FF] text-center mb-4'>instagram-clonm</h2>
        <img src={inst1} alt="Ecomerce" />
        <p className='text-gray-400 mt-4 mb-4'>This is a clear test</p>
        <div className='flex space-x-12 text-gray-400 text-center items-center justify-center'>
          <span>I used this</span>
          <p className='p-1 border-b text-gray-300'>Html, React and TaiwindCss</p>
          <a href='https://github.com/abshirafrica/instagram-clonm' target="_blank">
            <DiGithubBadge className='text-3xl hover:text-gray-500 hover:scale-105 cursor-pointer duration-300' /></a>
          
        </div>
    </div>
    <div className='text-center items-center justify-center'>
      <h2 className='text-2xl leading text-[#8C52FF] text-center mb-4'>instagram-clonm</h2>
        <img src={inst2} alt="Ecomerce" />
        <p className='text-gray-400 mt-4 mb-4'>This is a clear test</p>
        <div className='flex space-x-12 text-gray-400 text-center items-center justify-center'>
          <span>I used this</span>
          <p className='p-1 border-b text-gray-300'>Html, React and TaiwindCss</p>
          <a href='https://github.com/abshirafrica/instagram-clonm' target="_blank">
            <DiGithubBadge className='text-3xl hover:text-gray-500 hover:scale-105 cursor-pointer duration-300' /></a>
          
        </div>
    </div>
    <div className='text-center items-center justify-center'>
      <h2 className='text-2xl leading text-[#8C52FF] text-center mb-4'>Personal website</h2>
        <img src={pers} alt="Personal" />
        <p className='text-gray-400 mt-4 mb-4'>Personal website</p>
        <div className='flex space-x-12 text-gray-400 text-center items-center justify-center'>
          <span>I used this</span>
          <p className='p-1 border-b text-gray-300'>Html, Css, and JavaScript</p>
          <a href='https://github.com/abshirafrica/Personal-Portfolio' target="_blank">
            <DiGithubBadge className='text-3xl hover:text-gray-500 hover:scale-105 cursor-pointer duration-300' /></a>
          
        </div>
    </div>
    <div className='text-center items-center justify-center'>
      <h2 className='text-2xl leading text-[#8C52FF] text-center mb-4'>Ecemmerce Restaurant</h2>
        <img src={Eco} alt="Ecomerce" />
        <p className='text-gray-400 mt-4 mb-4'>Website Restaurant</p>
        <div className='flex space-x-12 text-gray-400 text-center items-center justify-center'>
          <span>I used this</span>
          <p className='p-1 border-b text-gray-300'>Html, React and Css</p>
          <a href='/' target="_blank">
            <DiGithubBadge className='text-3xl hover:text-gray-500 hover:scale-105 cursor-pointer duration-300' /></a>
          
        </div>
    </div>
  
  </motion.div>

 

<Sklstable />
<Haaraay />


    </>
  )
}

export default Projects
