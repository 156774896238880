import { useState } from "react"



const SkillisTable = (props) => {
    const [name, setNmae] = useState("")

    const handleSubmit = (event)=>{
            event.preventDefault(); 
            props.setNmae(name)
            
    }
  return (
    <div>
    
        <div className="mt-44 text-center items-center justify-center">
            <form onSubmit={handleSubmit}>
            <h2 className="text-2xl text-gray-400">Find out about Abshir Africa schedule and careers</h2>
                <input type='text' placeholder="Please enter your name" onChange={(event)=> setNmae(event.target.value)} className='rounde-sm p-3 w-96 border border-gray-400 placeholder-gray-400 focus:outline focus:ring-indigo-300 focus:border-inigo mb-4' /> <br/>
                <button type="submit" className="w-96 p-2 text-lg round-md text-white bg-[#8C52FF] focus:outline-none focus:ring-4">Click</button>
            </form>
        </div>
       
      
    </div>
  )
}

export default SkillisTable
