import { Route, Routes, useLocation } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import Projects from "./Projects";
import {AnimatePresence} from 'framer-motion';


const Main = () => {
    const location = useLocation()
  return (
    <div>

        <AnimatePresence>
             <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
              
            
          </Routes>
        </AnimatePresence>
        
    </div>
  )
}

export default Main
