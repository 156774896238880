

import {SiAltiumdesigner} from 'react-icons/si'
import {BsCodeSlash, BsPencilSquare} from 'react-icons/bs'
import {RiComputerLine} from 'react-icons/ri'
import {TbVirusSearch} from 'react-icons/tb'
import { motion } from 'framer-motion'

import img1 from '../africaall/Abshir11.jpeg'

const Serves = () => {
  return (
    <div className='flex items-center justify-center mt-14 space-x-10'>
      <motion.div initial={{x: -100}} animate={{x: 0}}>
        <img src={img1}  className='w-96 shadow-lg shadow-blue-400 rotate-12' />
        

      </motion.div>
      
      <motion.div initial={{x: 100, y: 100}} animate={{y: 0, x: 0}}>
       <div>
        <div className='flex  items-center text-[#8C52FF] gap-4 '>
          <BsPencilSquare className='font-bold text-4xl text-[#3D00B6]'/>
            <h1>Graphic Design</h1>
            <hr/>
            
            </div>
            <div className='pl-7 text-gray-400'>
            <p className='pl-7'>Creating a beautiful brand that is uniquely you across web design.</p>
        </div>
        </div>

        <div>
        <div className='flex  items-center text-[#8C52FF] gap-4'>
          <SiAltiumdesigner className='font-bold text-4xl text-[#3D00B6]'/>
            <h1>Website Design</h1>
            <hr/>
            
            </div>
            <div className='pl-7 text-gray-400'>
            <p className='pl-7'>Designing and building the interface, navigation and aesthetic of websites for   businesses and clients</p>
        </div>
        </div>

        <div>
        <div className='flex  items-center text-[#8C52FF] gap-4'>
          <BsCodeSlash className='font-bold text-4xl text-[#3D00B6]'/>
            <h1>Web Development</h1>
            <hr/>
            
            </div>
            <div className='pl-7 text-gray-400'>
            <p className='pl-7'>I Develop Small Business Web Applications As Well As Large Apps Like CRM, Softwares For Management System.</p>
        </div>
        </div>

        
        <div>
        <div className='flex  items-center text-[#8C52FF] gap-4'>
          <RiComputerLine className='font-bold text-4xl text-[#3D00B6]'/>
            <h1>E-Commerce</h1>
            <hr/>
            
            </div>
            <div className='pl-7 text-gray-400'>
            <p className='pl-7'>Having an E commerce website is the perfect way to get your business.</p>
        </div>
        </div>

        <div>
        <div className='flex  items-center text-[#8C52FF] gap-4'>
          <TbVirusSearch className='font-bold text-4xl text-[#3D00B6]'/>
            <h1>SEO & SMM</h1>
            <hr/>
            
            </div>
            <div className='pl-7 text-gray-400'>
            <p className='pl-7'>Once your website is up and running, you need to attract many visitors.</p>
        </div>
        </div>
        

        

        </motion.div>
        
    </div>
  )
}

export default Serves
