

const Tables = ({name, setNmae}) => {
  return (
    <>
    <hr/>
    <div className='pt-14 flex justify-between items-center text-center'>
       
          <h1 className='text-2xl pb-7 text-[#8C52FF] font-medium'>Welcome {name}!</h1> 
          <button onClick={()=> setNmae("")} className="text-2xl text-red-300">LogOut</button>
          </div>
          <h3 className='text-gray-500 flex gap-2'><h4 className='text-[#8C52FF]'>{ name }: </h4> My Name's Abshir Mohamed Ahmed, Some people call me Africa.</h3>
          <div>
            <p className='text-gray-400'>
            Senior Web Developer specializing in front end development. Experienced with all stages of the development cycle for dynamic web projects. Well-versed in numerous programming languages including Html, Css, 
            Bootstrap, Sass, TailwindCSS, JavaScript, NextJs, React, Php,  Wordpress and OOP. Strong background in project management and customer relations.
            </p>
            <p>
            <p className='mt-4 text-gray-500'>
               Welcome again <span className='text-[#8C52FF]'>{name}:</span> <br/>

               I am looking for a job, can you help me?
            </p>
            </p>
          </div>
          <h3 className='text-2xl leading text-[#8C52FF] text-center mb-12 mt-14 '>My schedule</h3>
    <div className='grid grid-cols-1 md:grid-cols-full gap-10 w-full bg-blue-100 justify-center items-center h-screen mb-96 mt-96'>
        <table className='shadow-2xl font-[Poppins] border-2 border-blue-200 overflow-hidden border-b-blue-900'>
            <thead className='text-white cursor-pointer'>
                <tr>
                    <th className='py-3 bg-blue-800'>Saturday</th>
                    <th className='py-3 bg-blue-800'>Sunday</th>
                    <th className='py-3 bg-blue-800'>Monday</th>
                    <th className='py-3 bg-blue-800'>Tuesday</th>
                    <th className='py-3 bg-blue-800'>Wednesday</th>
                    <th className='py-3 bg-blue-800'>Thursday</th>
                    <th className='py-3 bg-blue-800'>Friday</th>
                </tr>
            </thead>
            <tbody className='text-cyan-900 text-center'>
                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>4:30am <br/>Morning prayer</td>
                    <td className='py-3 px-6'>4:30am <br/>Morning prayer</td>
                    <td className='py-3 px-6'>4:30am <br/>Morning prayer</td>
                    <td className='py-3 px-6'>4:30am <br/>Morning prayer</td>
                    <td className='py-3 px-6'>4:30am <br/>Morning prayer</td>
                    <td className='py-3 px-6'>4:30am <br/>Morning prayer</td>
                    <td className='py-3 px-6'>4:30am <br/>Morning prayer</td>
                </tr>

                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>5:30am <br/>Qur,aan and play until 7:30am</td>
                    <td className='py-3 px-6'>5:30am <br/>Qur,aan and play until 7:30am</td>
                    <td className='py-3 px-6'>5:30am <br/>Qur,aan and play until 7:30am</td>
                    <td className='py-3 px-6'>5:30am <br/>Qur,aan and play until 7:30am</td>
                    <td className='py-3 px-6'>5:30am <br/>Qur,aan and play until 7:30am</td>
                    <td className='py-3 px-6'>5:30am <br/>Qur,aan and play until 7:30am</td>
                    <td className='py-3 px-6'>5:30am <br/>Qur,aan and play until 7:30am</td>
                </tr>

                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>9:00am <br/>Lessons and reading until 12:00am</td>
                    <td className='py-3 px-6'>9:00am <br/>Lessons and reading until 12:00am</td>
                    <td className='py-3 px-6'>9:00am <br/>Lessons and reading until 12:00am</td>
                    <td className='py-3 px-6'>9:00am <br/>Lessons and reading until 12:00am</td>
                    <td className='py-3 px-6'>9:00am <br/>Lessons and reading until 12:00am</td>
                    <td className='py-3 px-6'>8:00am <br/>Bula-Burte University Until 12:00am</td>
                    <td className='py-3 px-6'>8:00am <br/>Bula-Burte University Until 12:00am</td>
                </tr>

                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>12:00am<br/> Noon prayer</td>
                    <td className='py-3 px-6'>12:00am<br/> Noon prayer</td>
                    <td className='py-3 px-6'>12:00am<br/> Noon prayer</td>
                    <td className='py-3 px-6'>12:00am<br/> Noon prayer</td>
                    <td className='py-3 px-6'>12:00am<br/> Noon prayer</td>
                    <td className='py-3 px-6'>12:00am<br/> Noon prayer</td>
                    <td className='py-3 px-6'>12:00am<br/> Noon prayer</td>
                </tr>
                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>1:00pm<br/>Repeat lesson and short sleep until 15:00pm</td>
                    <td className='py-3 px-6'>1:00pm<br/>Repeat lesson and short sleep until 15:00pm</td>
                    <td className='py-3 px-6'>1:00pm<br/>Repeat lesson and short sleep until 15:00pm</td>
                    <td className='py-3 px-6'>1:00pm<br/>Repeat lesson and short sleep until 15:00pm</td>
                    <td className='py-3 px-6'>1:00pm<br/>Repeat lesson and short sleep until 15:00pm</td>
                    <td className='py-3 px-6'>1:00pm<br/>Repeat lesson and short sleep until 15:00pm</td>
                    <td className='py-3 px-6'>1:00pm<br/>Relax until 15:00pm</td>
                </tr>
                  <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>15:00pm<br/> Asr prayer</td>
                    <td className='py-3 px-6'>15:00pm<br/> Asr prayer</td>
                    <td className='py-3 px-6'>15:00pm<br/> Asr prayer</td>
                    <td className='py-3 px-6'>15:00pm<br/> Asr prayer</td>
                    <td className='py-3 px-6'>15:00pm<br/> Asr prayer</td>
                    <td className='py-3 px-6'>15:00pm<br/> Asr prayer</td>
                    <td className='py-3 px-6'>15:00pm<br/> Asr prayer</td>
                </tr>
                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>16:00pm<br/>Coffee and meeting friends until 18:00pm</td>
                    <td className='py-3 px-6'>16:00pm<br/>Coffee and meeting friends until 18:00pm</td>
                    <td className='py-3 px-6'>16:00pm<br/>Coffee and meeting friends until 18:00pm</td>
                    <td className='py-3 px-6'>16:00pm<br/>Coffee and meeting friends until 18:00pm</td>
                    <td className='py-3 px-6'>16:00pm<br/>Coffee and meeting friends until 18:00pm</td>
                    <td className='py-3 px-6'>16:00pm<br/>Coffee and meeting friends until 18:00pm</td>
                    <td className='py-3 px-6'>16:00pm<br/>Coffee and meeting friends until 18:00pm</td>
                </tr>
                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>18:00pm<br/>Evening prayer</td>
                    <td className='py-3 px-6'>18:00pm<br/>Evening prayer</td>
                    <td className='py-3 px-6'>18:00pm<br/>Evening prayer</td>
                    <td className='py-3 px-6'>18:00pm<br/>Evening prayer</td>
                    <td className='py-3 px-6'>18:00pm<br/>Evening prayer</td>
                    <td className='py-3 px-6'>18:00pm<br/>Evening prayer</td>
                    <td className='py-3 px-6'>18:00pm<br/>Evening prayer</td>
                </tr>
                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>19:00pm<br/>Isha prayer</td>
                    <td className='py-3 px-6'>19:00pm<br/>Isha prayer</td>
                    <td className='py-3 px-6'>19:00pm<br/>Isha prayer</td>
                    <td className='py-3 px-6'>19:00pm<br/>Isha prayer</td>
                    <td className='py-3 px-6'>19:00pm<br/>Isha prayer</td>
                    <td className='py-3 px-6'>19:00pm<br/>Isha prayer</td>
                    <td className='py-3 px-6'>19:00pm<br/>Isha prayer</td>
                </tr>
                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300'>
                    <td className='py-3 px-6'>20:00pm<br/>Lessons and reading until 2130pm</td>
                    <td className='py-3 px-6'>20:00pm<br/>Lessons and reading until 2130pm</td>
                    <td className='py-3 px-6'>20:00pm<br/>Lessons and reading until 2130pm</td>
                    <td className='py-3 px-6'>20:00pm<br/>Lessons and reading until 2130pm</td>
                    <td className='py-3 px-6'>20:00pm<br/>Lessons and reading until 2130pm</td>
                    <td className='py-3 px-6'>20:00pm<br/>Lessons and reading until 2130pm</td>
                    <td className='py-3 px-6'>20:00pm<br/>Relax until 2130pm</td>
                </tr>
                <tr className='bg-blue-200 hover:bg-cyan-100 hover:scale-105 cursor-pointer duration-300 '>
                    <td className='py-3 px-6'>21:45pm<br/>I do experimental projects until 23:30pm</td>
                    <td className='py-3 px-6'>21:45pm<br/>I do experimental projects until 23:30pm</td>
                    <td className='py-3 px-6'>21:45pm<br/>I do experimental projects until 23:30pm</td>
                    <td className='py-3 px-6'>21:45pm<br/>I do experimental projects until 23:30pm</td>
                    <td className='py-3 px-6'>21:45pm<br/>I do experimental projects until 23:30pm</td>
                    <td className='py-3 px-6'>21:45pm<br/>I do experimental projects until 23:30pm</td>
                    <td className='py-3 px-6'>20:00pm<br/>I do experimental projects until 23:30pm</td>
                </tr>


            </tbody>

        </table>

    



    </div>

    </>
  )
}

export default Tables
