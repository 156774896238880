import cover from '../africaall/cover.png'

import Serves from '../Components/Serves'
import Haaraay from './Haaraay'
import { motion } from 'framer-motion'
import Sklstable from './Sklstable'

const Home = () => {
  return (
   
    <div>
        <motion.div className='mt-5'>
            <img src={cover} alt="Cover" initial={{y: -100}} animate={{y: 0}}/>
        </motion.div>


           <motion.div className='flex items-center flex-col md:flex-row space-x-3'
           initial={{y: 100}} animate={{y: 0}}>
              <div className='space-y-4 mt-5'>
                  <h1 className='text-gray-400 text-sm leading-7 text-center font-bold'>I'm like you</h1>
                  <p className='text-gray-400'>I consult, I design, and I develop, all in-house. <br/> I call ourselves a creative studio working more efficient and effective in today’s design market.</p>
              </div>

              <div className='space-y-4 mt-5'>
                
                  <h1 className='text-gray-400 text-sm leading-7 text-center font-bold'>Responsibility</h1>
                  <p className='text-gray-400'>I'm comitted to deliver the most creative ideas to highlight your brand’s individuality.</p>
              </div>

              <div className='space-y-4 mt-5'>
                  <h1 className='text-gray-400 text-sm leading-7 text-center font-bold'>Professionality</h1>
                  <p className='text-gray-400'>Our award-winning designers perform their mastery for you and your target audience.</p>
              </div>


      </motion.div>




        
        <Serves />
        <Sklstable />
        <Haaraay />
    </div>

   

   
  )
}

export default Home
