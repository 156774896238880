import SkillisTable from "./SkillisTable";
import Tables from '../Components/Tables'
import { useEffect, useState } from "react";

const Sklstable = () => {

    const [name, setNmae] = useState("")
    useEffect(() =>{
        document.title = name ? `${name}'s feed ` : "Welcome to the Abshir Africa page"; 

    },[name]);

    if(!name) return <SkillisTable setNmae={setNmae} />
  return (
    <div>



       <Tables name={name} setNmae={setNmae} />
      
    </div>
  )
}

export default Sklstable
