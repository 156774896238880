import { BrowserRouter as Router } from "react-router-dom";

import Main from "./Components/Main";
import Header from './Components/Header';
import Footer from "./Components/Footer";
import { ThemeProvider } from "next-themes";


function App() {
  return (
   

<ThemeProvider>
<div className="max-w-5xl mx-auto p-5">

      <Router>
           <Header />
            <Main />
            <Footer />
      </Router>

      </div>

</ThemeProvider>


     
    
  );
}

export default App;
