import {BsTelephone} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'
import {CiLocationOn} from 'react-icons/ci'
import { motion } from 'framer-motion'
import Haaraay from './Haaraay'

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Sklstable from './Sklstable'


const Contact = () => {
    const form = useRef();
    const [send, setSend] = useState(false)
    // send email
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm(
            'service_9cxi27o', 
            'template_5pd76i8', 
            form.current, 
            'k2KKcTt9R7MOZQhgq')
          .then((result) => {
              console.log(result.text);
              setSend(true)
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <>
    <motion.div className='mt-20 flex flex-wrap sm:flex-nowrap items-center space-x-5 justify-around p-10 sm:px-6 lg:px-8' initial={{x: 100, y: 100}} animate={{y: 0, x: 0}}>
        {/* left */}
        <div className='space-y-7'>
            <h1 className='font-bold text-4xl text-[#8C52FF]'>Contact Info</h1>
            <p className='text-gray-400'>Plese feel free to contact me for details! if you have any question, I'm happy to answer them and to offer you the whole assortment of me services!</p>

            <div className='text-md flex space-x-2 items-center'>
                <BsTelephone className='text-[#3D00B6]'/>
                    <p className='text-gray-400' title='this is my WhatsApp'>+252618664727</p>
            </div>
            <div className='text-md flex space-x-2 items-center'>
                <AiOutlineMail className='text-[#3D00B6]'/>
                    <p className='text-gray-400' title='this is my email'>Abshir@haaraay.com</p>
            </div>
            <div className='text-md flex space-x-2 items-center'>
                <AiOutlineMail className='text-[#3D00B6]'/>
                    <p className='text-gray-400' title='this is my email'>Abshirhaaraay@gmail.com</p>
            </div>
            <div className='text-md flex space-x-2 items-center'>
                <CiLocationOn className='text-[#3D00B6]'/>
                    <p className='text-gray-400' title='this is my location'>Buulo_Burte, Hiiraan, Somalia</p>
            </div>
        </div>
    
        <form className='w-full max-md space-y-6 mt-5 ' onSubmit={sendEmail} ref={form}>
            <input type="text" placeholder='Enter your name' required className='rounde-sm p-3 w-full border border-gray-400 placeholder-gray-400 focus:outline focus:ring-indigo-300 focus:border-inigo' name="name" />

            <input type="email" placeholder='Enter your Email' required className='rounde-sm p-3 w-full border border-gray-400 placeholder-gray-400 focus:outline focus:ring-indigo-300 focus:border-inigo' name="email" />

                <textarea
                     type="text" placeholder='How can I help you' required className='rounde-sm p-8 w-full border border-gray-400 placeholder-gray-400 focus:outline focus:ring-indigo-300 focus:border-inigo' name="message">

                </textarea>
           
                <div className='grid grid-cols-2 md:grid-cols-2 mt-10 gap-10 w-full'>
                <div>
                <button type='submit' className='w-full p-2 text-lg round-md text-white bg-[#8C52FF] focus:outline-none focus:ring-4'>Send message </button>
            {
                send && (
                    <div className='p-4 mb-4 text-green-700 bg-green-100 rounded-lg' role="alert">
                        <span className='font-bold'>Thanks for your reaction </span> 
                        I will contact you as soon as possible
                    </div> 
                )
            }

                </div>
                
                <div>

                    <a target="_blan" href="https://api.whatsapp.com/send/?phone=+252618664727&amp;text=Thanks+for+your+contact+Abshir+Africa&amp;app_absent=0"><button type="button" className='w-full p-2 text-lg round-md text-white bg-[#8C52FF] focus:outline-none focus:ring-4 cursor-help'>Simple whatsApp </button></a>
  
                </div>
                </div>
               

             
         
        </form>

        
    </motion.div>
        <Sklstable />
        <Haaraay />
        </>
  )
}

export default Contact
