import React from 'react'

const Haaraay = () => {
  return (
   
        <div className='mt-40  h-36 text-center items-center bg-gradient-to-b from-[#3D00B6] to-blue-100 rounded-t-full'>

            <h1 className=' text-white text-2xl pt-14 items-center sm:text-center px-4 hover:-translate-y-1 duration-100  hover:text-black cursor-help'>Haaraay is the all-in-one platfrm to build a beautiful website</h1>

        </div>
      
   
  )
}

export default Haaraay
