
import { useState } from 'react'
import {AiOutlineMenuUnfold} from 'react-icons/ai'
import {GrFormClose} from 'react-icons/gr'
import { Link } from "react-router-dom"
import {CgSun} from 'react-icons/cg'
import { BsMoon } from 'react-icons/bs'
import logo from '../africaall/Logo.png'
import { useTheme } from 'next-themes'





const Header = () => {
  
    const [open, setOpen] = useState(false)
    const {theme, setTheme} = useTheme();

  return (
    <div className="flex items-center justify-between pt-2">
      {/* logo */}
      <div className="w-44 text-2xl text-blue-700 font-bold">
        <Link to="/"><img src={logo} alt="logo"/></Link>
      </div>

      {/* large screen navigtion */}
      <div className="hidden md:flex items-center gap-6 font-medium text-gray-600">
        <Link to="/">Home</Link>
        <Link to="/projects">Projects</Link>
        <Link to="/about">About</Link>
        <div className="bg-blue-700 text-white hover:-translate-y-1 duration-100 px-3 py-1 rounded-xl ">
        <Link to="/contact">Contact</Link>
        </div>
        <div className='cursor-pointer text-2xl'>

          {/* dark mode */}
          {
            theme === "dark" ? (
              <BsMoon className="w-7 cursor-pointer" onClick={() => setTheme("light")}/>
            ):( <CgSun onClick={() => setTheme("dark")} />)
          }
          
        </div>
      </div>

      {/* mobele navigtion */}

      <div className={`fixed top-0 z-50 bg-white w-60 h-screen shadow-2xl ${open ? 'right-0' : 'right-[-100%]'} md:hidden flex flex-col space-y-7 font-medium text-gray-600 pt-20 p-7 duration-100`}>
         <Link to="/">Home</Link>
         <Link to="/projects">Projects</Link>
         <Link to="/about">About</Link>
        <div className="bg-blue-400 w-24 text-white hover:-translate-y-1 duration-100 px-3 py-1 rounded-xl">
        <Link to="/contact">Contact</Link>
        </div>
              
      </div>


      {/* toggle button */}

      <div className='flex items-center space-x-4 md:hidden text-blue-400'>
           {/* dark mode */}
           {
            theme === "dark" ? (
              <BsMoon className="w-7 cursor-pointer" onClick={() => setTheme("light")}/>
            ):( <CgSun onClick={() => setTheme("dark")} className='text-2xl cursor-pointer' />)
          }
              
      
      <div className='text-2xl md:hidden z-50' onClick={() =>
        setOpen(!open)}>
        {
          open ? (
            <GrFormClose className='h-7 cursor-pointer' />
          ):
            
           <AiOutlineMenuUnfold className='h-7 cursor-pointer mr-7' />
        }
     
      </div>

      </div>
     
     
        
    </div>
    
  )
}

export default Header
