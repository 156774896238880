import adan from '../africaall/Adan.jpeg'
import mohamed from '../africaall/Mohamed.jpeg'

const Testimonial = () => {
  return (
    <div>

                <div className='text-center text-3xl mt-16 text-[#8C52FF] font-medium'>
                  <h1>My happy clients</h1>
                </div>


                <div className='grid grid-cols-1 md:grid-cols-2 mt-10 gap-10 w-full  '>

                <div className='rounded-3xl shadow-md p-10' >
                <div className='flex items-center pb-4'>
                  <img src={adan} alt="Aadan Image" className='w-12 h-12 rounded-full object-cover'/>
                   <h1 className='ml-5 space-y-0 font-bold text-base text-gray-500'>Teacher Aadan</h1>
                </div>
                  <p className='text-gray-400 font-normal'>What really attracted me was the articulated design and the lovely colour used this also shows it was done by well experienced and knowledgeable person because it is not easy to come up with such attractive work without doing extra and calculated steps. I would like to encourage the expertise to continue their efforts and never hesitate to invent much and more products and services</p>
                </div>
                <div className='rounded-3xl shadow-md p-10'>
                  <div className='flex items-center pb-4'>
                  <img src={mohamed} alt="Aadan Image" className='w-12 h-12 rounded-full object-cover'/>
                   <h1 className='ml-5 space-y-0 font-bold text-base text-gray-500'>Dr. Mohamed Hassan</h1>
                </div>
                  <p className='text-gray-400 font-normal'>My eyes attracted by the good look of the design and the efforts that was put on this marvelous work. I can say the designer had an ambitious of ensuring that his customers receive a service that inspires them as well the quality of the service. 
                  I am here to assure you what Abshir Africa is doing is something of great because he has ambitious and have mission and vision.</p>
                </div>

                </div>

                    
    
    </div>
  )
}

export default Testimonial


