import {GrReactjs} from 'react-icons/gr'
import {SiVisualstudiocode} from 'react-icons/si'
import {GiTripleBeak} from 'react-icons/gi'
import {VscGitMerge} from 'react-icons/vsc'
import {SiTailwindcss} from 'react-icons/si'
import {SiCanva} from 'react-icons/si'
import {TbBrandFacebook, TbSchool} from 'react-icons/tb'
import {BsCode, BsStrava, BsBook, BsBootstrap} from 'react-icons/bs'
import about from '../africaall/about.png'
import Haaraay from './Haaraay'
import { motion } from 'framer-motion'
import Tastimonia from './Tastimonia'
import Sklstable from './Sklstable'


const About = () => {
   
  return (
<>
      <motion.div className='mt-5' initial={{x: -100}} animate={{x: 0}}>
        <img src={about} alt="About-image" />
      </motion.div>

      <div className='grid grid-col-1 md:grid-cols-2 mt-24 max-w-4xl mx-auto p-5'>
      {/* left */}
      <div className='flex items-center flex-wrap relative justify-center h-max'>
      <h3 className='text-2xl leading text-[#8C52FF] text-center mb-4 '>Expertise:</h3>
      <div className=' mt-5 max-w-3xl mx-auto p-5'>
       
        <p>⇒ Design and Develop Responsive website with react and tailwindCss.</p>
        <p>⇒ Convert Any PSD Template or sketch Into html website.</p>
        <p>⇒ Deep functional knowledge or hands on design experience with Web Services (REST, SOAP, etc ..).</p>
        <p>⇒ Design and Develop Responsive WP Websites (Personal, Blog, business wesbite, etc.).</p>
        <p>⇒ Regular exposure to business stakeholders and executive management, as well as the <br />authority and scope to apply my expertise to many interesting technical problems.</p>
        <p>⇒ Design and Develop Responsive website with html5 css3 javascript and bootstrap.</p>
        <p>⇒ php crude operation Feel free to for any other query and put order.</p>
      </div>
      </div>
      
      {/* right */}
      <motion.div className='p-5 md:p-0 md:text-left text-center' initial={{y: -100}} animate={{y: 0}}>
       
          {/* tools */}

        
          <h3 className='text-2xl leading text-[#8C52FF] text-center mb-14'>Tools:</h3>
            <div className='flex items-center flex-wrap w-full  '>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <SiVisualstudiocode className='text-[#3D00B6]' /> VScood

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <GrReactjs className='text-[#3D00B6]' /> React

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <SiTailwindcss className='text-[#3D00B6]'/> Taiwincss

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <BsBootstrap className='text-[#3D00B6]'/> Bootstrap

                </div>
               
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <SiCanva className='text-[#3D00B6]' /> Canva

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <VscGitMerge className='text-[#3D00B6]' /> GitHub

                </div>
            </div>
         
        

        {/* interest */}
        

        <div className='mt-7 '>
            <h2 className='text-2xl leading text-[#8C52FF] text-center'>Interests</h2>
            <div className='flex items-center flex-wrap w-full '>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <BsCode className='text-[#3D00B6]'/> Coding

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <BsStrava className='text-[#3D00B6]'/> Travellng

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <BsBook className='text-[#3D00B6]'/> Reading

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                    <TbSchool className='text-[#3D00B6]'/> Learning

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7 text-lg'>
                <GiTripleBeak className='text-[#3D00B6]'/> Meeting

                </div>
                <div className='m-3 flex items-center text-center rounded-xl bg-gray-300 w-28 justify-center h-7    text-lg'>
                    <TbBrandFacebook className='text-[#3D00B6]'/> FaceBook

                </div>
            </div>
          </div>
      </motion.div>

    
    </div>

    <Tastimonia />

    {/* <Tables /> */}

    
    <Sklstable />

    <Haaraay />
      </>
    
  )
}

export default About
