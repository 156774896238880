import {CiFacebook} from 'react-icons/ci'
import {RiLinkedinLine} from 'react-icons/ri'
import {AiFillGithub} from 'react-icons/ai'
import {TbBrandInstagram} from 'react-icons/tb'

const Footer = () => {
  return (
    <div className="flex items-center justify-between mt-14 text-gray-400">
        <div>
            <h3>Copyright &copy; <a href="https://haaraay.com/">Abshir Africa</a></h3>
        </div>
        <div className='flex items-center justify-center space-x-7 text-2xl text-[#8C52FF]'>
        <div>
              <a href='https://www.facebook.com/abshir.mohamedharaay' target="_blank"><CiFacebook /></a>  
            </div>

            <div>
              <a href='https://www.instagram.com/abshir.mohamedharaay/' target="_blank"><TbBrandInstagram /></a>  
            </div>


            <div>
              <a href='https://www.linkedin.com/in/abshir-mohamed-ahmed-173454230/' target="_blank"><RiLinkedinLine /></a>  
            </div>
            <div>
               <a href='https://github.com/abshirafrica/' target="_blank"><AiFillGithub /></a> 
            </div>
        </div>
      
    </div>
  )
}

export default Footer
